import { registerApplication, start } from 'single-spa';
import {
  constructApplications,
  constructLayoutEngine,
  constructRoutes,
} from 'single-spa-layout';
import i18n from './i18nextfile';
import microfrontendLayout from './microfrontend-layout.html';

const replaced = microfrontendLayout.replace(
  /BASENAMEPLACEHOLDER/g,
  window._env_.BASENAME || ''
);


// loader 
const dot1 = '/img/shared-img/loader/spinner-dot-1.png';
const dot2 = '/img/shared-img/loader/spinner-dot-2.png';
const dot3 = '/img/shared-img/loader/spinner-dot-3.png';

const customLoader = `<div class="loader-container">
    <div class="loader-icons">
        <img class="dot-1" src="${dot1}" alt="">
        <img class="dot-2" src="${dot2}" alt="">
        <img class="dot-3" src="${dot3}" alt="">
    </div>
    </div>`;


const routes = constructRoutes(replaced, {
  loaders: { customLoader },
  props: {
    basename: window._env_.BASENAME,
    matomoAnalyticsContainer: window._env_.MATOMO_ANALYTICS_CONTAINER,
    matomoSiteId: window._env_.MATOMO_SITE_ID,
    features: window._env_.FEATURES,
    i18n,
  },
});

function prepareAppsToDisable(appName: string) {
  return ['@tactfulEngage/topnav', '@tactfulEngage/navbar'].includes(appName);
}

function disableAppInDisallowedRoutes(location: Location, appName: string): boolean {
  const disablingRoutes: { [key: string]: string[] } = {
    "@tactfulEngage/topnav": ["/register", "/preview"],
    "@tactfulEngage/navbar": ["/register", "/preview", "/workspaces", "/my-invitations", "/manage-subscription"],
  };
  return disablingRoutes[appName]?.some((route) => location.pathname.includes(route)) == false;
}

const applications = constructApplications({
  routes,
  //@ts-ignore
  loadApp({ name }) {
    return System.import(name);
  },
});

applications
  .filter((application) => prepareAppsToDisable(application.name))
  .forEach((application) => {
    application.activeWhen = [
      (location) => disableAppInDisallowedRoutes(location, application.name),
    ];
  });

const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();
start();
