import i18next from 'i18next';

const supportedLngs = [
  'de',
  'dk',
  'en',
  'es',
  'et',
  'fi',
  'fr',
  'it',
  'jp',
  'lt',
  'lv',
  'nl',
  'nl-BE',
  'no',
  'sv',
  'sv-FI',
  'zh',
];

i18next.init({
  fallbackLng: 'en',
  supportedLngs,
  interpolation: {
    escapeValue: false,
  },
});

(global.window as any).i18n = i18next;

export default i18next;
